import {
  Fragment, ReactNode, useContext, useEffect,
} from 'react';
import ReactTooltip, { TooltipProps as ReactTooltipProps } from 'react-tooltip';
import { ThemeContext } from '@app/contexts/theme-context';
import { differenceInDays } from 'date-fns';

export interface TooltipProps extends ReactTooltipProps {
  id: string,
  children: ReactNode,
  showInitial?: boolean,
  initialShowDurationDay?: number,
}

const LS_TOOLTIP_PREFIX = 'bidding:tooltip';

const Tooltip = ({
  showInitial, children, id, initialShowDurationDay = 5, place, ...rest
}: TooltipProps) => {
  const lsKey = `${LS_TOOLTIP_PREFIX}-${id}`;
  const tooltip = document.querySelectorAll(`[data-tip][data-for="${id}"]`)[0];
  const lsValue = localStorage.getItem(lsKey);

  const showTooltip = () => {
    ReactTooltip.show(tooltip);
  };

  useEffect(() => {
    const date = new Date(lsValue);
    const dayDifference = differenceInDays(new Date(), date);

    if (dayDifference >= initialShowDurationDay && showInitial) {
      // reset the reminder date
      localStorage.setItem(lsKey, new Date().toISOString());

      showTooltip();
    }
  }, []);

  const { theme } = useContext(ThemeContext);
  const isDark = theme === 'dark';

  return (
    <Fragment>
      {children}
      <ReactTooltip
        id={id}
        place={place}
        type={isDark ? 'dark' : 'light'}
        effect="solid"
        className="max-w-xs shadow-md"
        delayHide={500}
        clickable
        {...rest}
      />
    </Fragment>
  );
};

export default Tooltip;
