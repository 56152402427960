import { useContext } from 'react';
import Icon from '@mdi/react';
import Link from 'next/link';
import * as ROUTES from '@app/constants/routes';
import { useRouter } from 'next/router';
import { SIDEBAR_WIDTH, Z_INDECES } from '@app/constants/default';
import { SidebarContext } from '@app/contexts/sidebar-context';
import {
  mdiChevronRight,
  mdiCog, mdiEmailNewsletter, mdiHelpCircle, mdiHome, mdiLogout, mdiStickerCheck,
} from '@mdi/js';
import { handleSignOut } from './auth-guard';
import Tooltip from './tooltip';

export interface SidebarLinkProps {
  title: string,
  href: string,
  icon: string,
}

// add selected support
const SidebarLink = ({
  title, href, icon,
}: SidebarLinkProps) => {
  const router = useRouter();
  const { isExpanded } = useContext(SidebarContext);

  const [, secondPath] = router.pathname.split('/');
  const isMatch = secondPath === href.replace(/^\//, '');

  const backgroundHoverStyle = 'hover:bg-background-low-em dark:hover:bg-background-low-em-dark';
  const iconHoverStyle = 'hover:text-primary dark:hover:text-primary';
  const hoverAnimation = 'transition-all duration-300 ease-linear';
  const hoverStyle = `${backgroundHoverStyle} ${iconHoverStyle} ${hoverAnimation}`;

  const iconUnselectedStyle = `text-text-color-low-em  dark:text-text-color-mid-em rounded-xl
  ${isExpanded ? 'text-text-color-disabled dark:text-text-color-disabled' : ''} ${hoverStyle}`;
  const iconSelectedStyle = 'text-light bg-primary-light rounded-xl';

  const iconTitleStyle = 'flex justify-start text-base w-full ml-3 mt-1';

  const textHoverStyle = 'hover:text-text-color-high-em dark:hover:text-text-color-high-em-dark';
  const isSelectedTextStyle = isMatch ? 'text-light' : `text-text-color-disabled ${textHoverStyle}`;

  return (
    <Link href={href}>
      <li data-for="sidebarTip" data-tip={isExpanded ? '' : title}>
        <Tooltip id="sidebarTip" place="right" delayHide={100}>
          <span className={
            `cursor-pointer text-2xl py-3 px-3 flex items-center ${isExpanded ? 'justify-start' : 'justify-center'}
          ${isMatch ? iconSelectedStyle : iconUnselectedStyle}`
          }
          >
            <Icon path={icon} size={1.2} />
            <span className={`${iconTitleStyle} ${isSelectedTextStyle} ${isExpanded ? '' : 'hidden'}`}>
              {title}
            </span>
          </span>
        </Tooltip>
      </li>
    </Link>
  );
};

const SidebarToggle = () => {
  const { isExpanded, setIsExpanded } = useContext(SidebarContext);

  const buttonStyle = 'bg-light dark:bg-background-low-em-dark drop-shadow-md rounded-full w-8 h-8 ';
  const positionStyle = 'flex justify-center items-center absolute -right-4 ';
  const iconStyle = isExpanded ? '-mr-1' : 'ml-1';

  return (
    <button aria-label="Expand Sidebar" type="button" onClick={() => setIsExpanded(!isExpanded)}>
      <div className={`${buttonStyle} ${positionStyle} ${isExpanded ? 'rotate-180' : ''} hover:scale-125 duration-500`}>
        <span className="text-lg flex justify-center items-center">
          <Icon className={iconStyle} path={mdiChevronRight} size={1} />
        </span>
      </div>
    </button>
  );
};

const Sidebar = () => {
  const { isExpanded } = useContext(SidebarContext);
  const colorTransition = 'transition-background-color duration-500 ease-linear';
  const lightStyle = isExpanded ? `bg-base-dark ${colorTransition}` : `bg-base ${colorTransition}`;
  const darkStyle = isExpanded ? `bg-base-dark ${colorTransition}` : `bg-background-main-dark ${colorTransition}`;
  const expandTransition = isExpanded ? 'w-60 transition-width duration-500 ease' : '';

  return (
    <div className="flex hidden md:block" style={{ width: SIDEBAR_WIDTH }}>
      <aside
        style={{ zIndex: Z_INDECES.SIDEBAR }}
        className={`${lightStyle} fixed dark:${darkStyle} min-h-screen px-3 shadow-md`}
      >
        <div className={`top-0 py-5 text-center ${expandTransition} flex flex-col justify-between h-screen`}>
          <div>
            <Link href={ROUTES.HOME_PAGE}>
              <button aria-label="Home" type="button" className="mb-8 cursor-pointer flex items-center">
                <img src="/images/logo.png" alt="swbid-logo" height="40" width="50" />
                <p className={`text-xl ml-1 ${isExpanded ? 'text-light' : 'hidden'}`}>
                  SIMPLE
                  <span className="font-semibold">WASTE</span>
                </p>
              </button>
            </Link>
            <SidebarToggle />
            <ul className="flex-1 space-y-6 mt-14">
              <SidebarLink title="Home" icon={mdiHome} href={ROUTES.HOME_PAGE} />
              <SidebarLink title="Open Bids" icon={mdiEmailNewsletter} href={ROUTES.OPEN_BIDS_PAGE} />
              <SidebarLink
                title="Track Submitted Bids"
                icon={mdiStickerCheck}
                href={ROUTES.TRACK_SUBMITTED_BIDS_PAGE}
              />
              <SidebarLink title="Get Help" icon={mdiHelpCircle} href={ROUTES.HELP_PAGE} />
              <SidebarLink title="Settings" icon={mdiCog} href={ROUTES.SETTINGS_PAGE} />
            </ul>
          </div>
          <div>
            <button
              aria-label="Sign Out"
              type="button"
              className={`text-white text-2xl cursor-pointer w-full 
                hover:bg-background-low-em dark:hover:bg-background-low-em-dark rounded-xl
                ${isExpanded
                ? 'text-text-color-disabled dark:text-text-color-disabled'
                : 'text-text-color-low-em  dark:text-text-color-mid-em'
                } `}
              onClick={() => handleSignOut()}
              data-tip="Sign out"
            >
              <span
                className={`cursor-pointer text-2xl py-3 px-3 flex items-center 
                ${isExpanded ? 'justify-start' : 'justify-center'
                } rounded-xl transition-all duration-300 ease-linear hover:text-primary dark:hover:text-primary`}
              >
                <Icon path={mdiLogout} size={1.2} />
                <span className={`flex justify-start text-base w-full ml-3 mt-1 
                text-text-color-disabled hover:text-text-color-high-em dark:hover:text-text-color-high-em-dark 
                ${isExpanded ? '' : 'hidden'}
               `}
                >
                  Sign Out
                </span>
              </span>
            </button>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
