import { NAV_HEIGHT, Z_INDECES } from '@app/constants/default';
import { HOME_PAGE, SETTINGS_PAGE } from '@app/constants/routes';
import { useThemeContext } from '@app/contexts/theme-context';
import { frontendApi } from '@app/lib/api';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { mdiCog, mdiInformation } from '@mdi/js';
import Icon from '@mdi/react';
import Button from './button';
import NavInfoModal from './nav-info-modal';
import NavbarAuth from './navbar-auth';
import NavbarTheme from './navbar-theme';
import IconButton from './icon-button';

export interface NavbarProps {
  title: string;
}

const NavInfo = ({ setOpen }: any) => {
  const { isMobile } = useThemeContext();

  return (
    <div className="flex items-center">
      <IconButton
        onClick={() => setOpen(true)}
        icon={(
          <Icon
            size={1}
            path={mdiInformation}
            className="text-warning-dark"
          />
        )}
      />
      <div className={`py-[2.5px] px-[7px] absolute top-[15px] bg-red-500 rounded-full
          ${isMobile ? 'text-[7px] right-[102px]' : ' text-[8px] right-[113px]'}
          text-light`}
      >
        1
      </div>
    </div>
  );
};

const Navbar = () => {
  const navClassName = `bg-light fixed md:sticky top-0 w-full dark:bg-background-main-dark
  dark:text-white flex px-6 py-3 md:p-5 justify-between align-center shadow-sm`;
  const [loadingCancelEmail, setLoadingCancelEmail] = useState(false);
  const [userData, setUserData] = useState(null);
  const { isMobile, isTablet } = useThemeContext();

  const getCurrentUser = async () => {
    const response = await frontendApi.get('auth/me');
    setUserData(response.data);
  };

  const cancelEmailChange = async () => {
    try {
      setLoadingCancelEmail(true);
      await frontendApi.put('/auth/cancel-email');
      await getCurrentUser();
    } catch {
      console.error('Error cancelling email change');
    } finally {
      setLoadingCancelEmail(false);
      toast.success('Successfully Cancelled Email Change Request');
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <nav className={navClassName} style={{ height: NAV_HEIGHT, zIndex: Z_INDECES.NAVBAR }}>
      <div className="space-x-3 flex-grow flex items-center justify-between md:justify-end">
        {
          userData && userData.statuses.user_status === 'changed' && (
            <div className="hidden lg:flex w-full justify-center items-center">
              <div className="flex flex-row space-x-2 items-center">
                <p className="text-warning-dark lg:text-body-md">
                  You have pending email change. A confirmation email was sent to your new email.
                </p>
                <Button
                  buttonType="secondary"
                  onClick={() => cancelEmailChange()}
                  className="!px-2 !py-1 !rounded-lg"
                  disabled={loadingCancelEmail}
                >
                  {loadingCancelEmail ? 'Cancelling' : 'Cancel Change'}
                </Button>
              </div>
            </div>
          )
        }
        {
          userData && userData.statuses.user_status === 'changed' && (
            isTablet && (
            <div className="flex items-center">
              <NavInfoModal cancelChange={cancelEmailChange} isLoadingCancelEmail={loadingCancelEmail}>
                {(setOpen) => (
                  <NavInfo setOpen={setOpen} />
                )}
              </NavInfoModal>
            </div>
            )
          )
        }
        <div className="md:hidden flex mt-1 items-center">
          <Link href={HOME_PAGE}>
            <span className="cursor-pointer">
              <Image src="/images/logo.png" height="30" width="40" />
            </span>
          </Link>
        </div>
        <div className="flex items-center space-x-3">
          {userData && userData.statuses.user_status === 'changed' && (
            isMobile && (
              <div className="flex items-center">
                <NavInfoModal cancelChange={cancelEmailChange} isLoadingCancelEmail={loadingCancelEmail}>
                  {(setOpen) => (
                    <NavInfo setOpen={setOpen} />
                  )}
                </NavInfoModal>
              </div>
            )
          )}
          <NavbarTheme />
          <div className="md:hidden">
            <Link href={SETTINGS_PAGE}>
              <span className="cursor-pointer flex justify-center items-center">
                <Icon
                  size={1}
                  path={mdiCog}
                />
              </span>
            </Link>
          </div>
        </div>
        <div className="hidden md:block">
          <NavbarAuth />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
