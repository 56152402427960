import { ReactNode, Fragment, useContext } from 'react';
import { useAppContext } from '@app/contexts/app-context';
import { SidebarContext } from '@app/contexts/sidebar-context';
import { SIDEBAR_WIDTH, NAV_HEIGHT, Z_INDECES } from '@app/constants/default';
import { useThemeContext } from '@app/contexts/theme-context';
import Navbar from './navbar';
import SideBar from './sidebar';
import BottomNavbar from './bottom-navbar';

export interface AuthLayoutProps {
  children: ReactNode
}

const AuthLayout = ({ children }: AuthLayoutProps) => {
  const { setIsExpanded } = useContext(SidebarContext);
  const { isOpenOnboardingModal } = useAppContext();
  const { isMobile } = useThemeContext();

  return (
    // NOTE: added pl for main element to give space as it ignores the fixed display of aside.
    <Fragment>
      {isMobile ? (
        // Only displayed in mobile
        <div className={`h-screen overflow-y-auto ${isOpenOnboardingModal ? 'no-scrollbar' : ''}`}>
          <Navbar />
          <div className="pb-20" style={{ paddingTop: NAV_HEIGHT }}>
            {children}
          </div>
          <BottomNavbar />
        </div>
      ) : (
        // Only displayed in desktop
        <Fragment>
          <SideBar />
          <main
            role="presentation"
            onClick={() => setIsExpanded(false)}
            className="flex-1 relative min-h-screen bg-light pl-16 dark:bg-background-main-dark"
            style={{ paddingLeft: SIDEBAR_WIDTH, zIndex: Z_INDECES.AUTH_LAYOUT }}
          >
            <Navbar />
            {children}
          </main>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AuthLayout;
