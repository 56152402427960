import { useState } from 'react';
import { useSession } from 'next-auth/react';
import { mdiAccount } from '@mdi/js';
import Icon from '@mdi/react';
import Button from './button';
import NavAuthModal from './nav-auth-modal';

const NavbarAuthUserImage = ({ userImg }: { userImg: any }) => (
  userImg
    ? <img className="h-8 w-8 rounded-md block" src={userImg} alt={userImg} />
    : (
      <div className="rounded-xl bg-secondary h-9 w-10 flex justify-center items-center text-lg text-white">
        <Icon
          size={1}
          path={mdiAccount}
        />
      </div>
    )
);

const NavbarAuth = () => {
  const { data: session, status } = useSession();
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  return (
    <div className="flex items-center">
      <div className="2l-1 relative">
        {status === 'authenticated' && (
          <div>
            <button
              onClick={() => setUserMenuOpen(!userMenuOpen)}
              type="button"
              className="flex text-sm rounded-full focus:outline-none"
              id="user-menu"
              aria-expanded="false"
              aria-haspopup="true"
            >
              <span className="sr-only">Open user menu</span>
            </button>
            <NavAuthModal user={session.user}>
              {(setOpen) => (
                <Button aria-label="User Menu" onClick={() => setOpen(true)} className="!p-0 !bg-transparent">
                  <NavbarAuthUserImage userImg={session.user.image} />
                </Button>
              )}
            </NavAuthModal>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavbarAuth;
