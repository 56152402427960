import { useThemeContext } from '@app/contexts/theme-context';
import { Z_INDECES } from '@app/constants/default';
import {
  useState, Fragment, ReactNode,
} from 'react';
import ReactModal, { Props } from 'react-modal';
import { extractedTailwindTheme } from '@app/lib/helpers';
import Button from './button';

ReactModal.setAppElement('#__next');

export interface ModalProps extends Omit<Props, 'isOpen'> {
  children: (t: any) => ReactNode,
  initialState?: boolean,
  cancelChange: () => void,
  isLoadingCancelEmail: boolean,
}

const NavInfoModal = ({
  children, initialState = false, cancelChange, isLoadingCancelEmail, ...modalProps
}: ModalProps) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const { theme, isMobile } = useThemeContext();
  const isDark = theme === 'dark';

  const customModalStyles = {
    overlay: {
      backgroundColor: 'transparent',
      zIndex: Z_INDECES.NAV_AUTH_MODAL,
    },
    content: {
      borderRadius: 10,
      top: 65,
      right: isMobile ? 10 : 110,
      left: 'auto',
      bottom: 'auto',
      maxWidth: isMobile ? '13rem' : '18rem',
      minHeight: '6rem',
      borderColor: isDark ? extractedTailwindTheme.colors.background['low-em'].dark
        : extractedTailwindTheme.colors.background.main,
      backgroundColor: isDark
        ? extractedTailwindTheme.colors.background.main.dark : extractedTailwindTheme.colors.background.main,
      padding: 10,
      boxShadow: '0 3px 3px rgba(0, 0, 0, 0.3)',
    },
  };

  return (
    <Fragment>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        style={customModalStyles}
        {...modalProps}
      >
        <div className="flex flex-col space-y-2">
          <p className="text-warning-dark text-sm">
            You have pending email change. A confirmation email was sent to your new email.
          </p>
          <div className="space-y-2">
            <Button
              full
              disabled={isLoadingCancelEmail}
              onClick={() => cancelChange()}
              buttonType="secondary"
            >
              {isLoadingCancelEmail ? 'Cancelling' : 'Cancel Change'}
            </Button>
            <Button full onClick={() => setIsOpen(false)}>Close</Button>
          </div>
        </div>
      </ReactModal>
      {children(setIsOpen)}
    </Fragment>
  );
};

export default NavInfoModal;
