import Link from 'next/link';
import { useRouter } from 'next/router';
import * as ROUTES from '@app/constants/routes';
import Icon from '@mdi/react';
import {
  mdiAccount, mdiEmailNewsletter, mdiHome, mdiMessage, mdiStickerCheck,
} from '@mdi/js';
import { useThemeContext } from '@app/contexts/theme-context';

const BottomNavbar = () => {
  const { handleLiveChatVisibilityToggle } = useThemeContext();
  const router = useRouter();
  const navItems = [
    {
      icon: mdiHome,
      href: ROUTES.HOME_PAGE,
      title: 'Dashboard',
    },
    {
      icon: mdiEmailNewsletter,
      href: ROUTES.OPEN_BIDS_PAGE,
      title: 'Open Bids',
    },
    {
      icon: mdiAccount,
      href: ROUTES.PROFILE_PAGE,
      title: 'Profile',
    },
    {
      icon: mdiStickerCheck,
      href: ROUTES.TRACK_SUBMITTED_BIDS_PAGE,
      title: 'Track Bids',
    },
    // {
    //   icon: 'cog',
    //   href: ROUTES.SETTINGS_PAGE,
    //   title: 'Settings',
    // },
  ];

  const isActive = (path) => path === router.pathname;

  return (
    <nav className="px-6 pt-2 pb-1 fixed bottom-0 w-full bg-background-main dark:bg-background-main-dark shadow-2xl">
      <div className="flex-grow flex flex-row">
        {navItems.map((navItem) => (
          <Link key={navItem.href} href={navItem.href}>
            <span className="flex flex-col items-center space-y-1 w-1/5 cursor-pointer pb-1 xs:pb-0">
              <span
                className={`py-2 px-3 ${
                  isActive(navItem.href) ? 'bg-primary-light rounded-xl' : ''
                }`}
              >
                <Icon
                  path={navItem.icon}
                  className={
                    `h-6 w-6 
                      ${isActive(navItem.href)
                      ? 'text-text-color-light'
                      : 'text-text-color-low-em dark:text-text-color-mid-em'}`
                  }
                />
              </span>
              <span
                className={`
              ${
                isActive(navItem.href)
                  ? 'text-primary-light'
                  : 'text-text-color-low-em dark:text-text-color-mid-em'
              } 
              caption text-center hidden xs:block`}
              >
                {navItem.title}
              </span>
            </span>
          </Link>
        ))}
        <div
          className="flex flex-col items-center space-y-1 w-1/5 cursor-pointer pb-1 xs:pb-0"
          onClick={handleLiveChatVisibilityToggle}
          role="presentation"
        >
          <span className="py-2 px-3 ">
            <Icon
              path={mdiMessage}
              className="text-text-color-low-em dark:text-text-color-mid-em h-6 w-6"
            />
          </span>
          <span className="
              text-text-color-low-em dark:text-text-color-mid-em
              caption text-center hidden xs:block"
          >
            LiveChat
          </span>
        </div>
      </div>
    </nav>
  );
};

export default BottomNavbar;
