import { useThemeContext } from '@app/contexts/theme-context';
import { Z_INDECES } from '@app/constants/default';
import {
  useState, Fragment, ReactNode,
} from 'react';
import ReactModal, { Props } from 'react-modal';
import { extractedTailwindTheme } from '@app/lib/helpers';
import Link from 'next/link';
import { mdiAccount } from '@mdi/js';
import Icon from '@mdi/react';
import { HELP_PAGE, PROFILE_PAGE, SETTINGS_PAGE } from '@app/constants/routes';
import { handleSignOut } from './auth-guard';

ReactModal.setAppElement('#__next');

export interface ModalProps extends Omit<Props, 'isOpen'> {
  children: (t: any) => ReactNode,
  initialState?: boolean,
  user?: any,
}

const NavbarAuthUserImage = ({ userImg }: { userImg: string }) => (
  userImg
    ? <img className="h-8 w-8 rounded-md block" src={userImg} alt={userImg} />
    : (
      <div className="rounded-xl bg-secondary h-9 w-10 flex justify-center items-center text-lg text-white">
        <Icon
          size={1}
          path={mdiAccount}
        />
      </div>
    )
);

const NavAuthModal = ({
  children, initialState = false, user, ...modalProps
}: ModalProps) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const { theme } = useThemeContext();
  const isDark = theme === 'dark';

  const customModalStyles = {
    overlay: {
      backgroundColor: 'transparent',
      zIndex: Z_INDECES.NAV_AUTH_MODAL,
    },
    content: {
      borderRadius: 10,
      top: 65,
      right: 20,
      left: 'auto',
      bottom: 'auto',
      maxWidth: '25rem',
      minWidth: '20rem',
      minHeight: '16rem',
      borderColor: isDark ? extractedTailwindTheme.colors.background['low-em'].dark
        : extractedTailwindTheme.colors.background.main,
      backgroundColor: isDark
        ? extractedTailwindTheme.colors.background.main.dark : extractedTailwindTheme.colors.background.main,
      padding: 0,
      boxShadow: '0 3px 3px rgba(0, 0, 0, 0.3)',
    },
  };

  return (
    <Fragment>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        style={customModalStyles}
        {...modalProps}
      >
        <div>
          <div className="flex items-center my-3 space-x-3  px-5">
            <NavbarAuthUserImage userImg={user.image} />
            <div>
              <Link href={PROFILE_PAGE}>
                <p className="text-sm font-bold cursor-pointer break-words">{user.name}</p>
              </Link>
              <small className="text-xs break-all">{user.email}</small>
            </div>
          </div>
          <Link href={PROFILE_PAGE}>
            <p className="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-secondary-dark cursor-pointer">
              Profile
            </p>
          </Link>
          <Link href={`${PROFILE_PAGE}?tab=vendorProfile`}>
            <p className="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-secondary-dark cursor-pointer">
              Vendor Profile
            </p>
          </Link>
          <Link href={`${PROFILE_PAGE}?tab=submittedBids`}>
            <p className="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-secondary-dark cursor-pointer">
              All My Bids
            </p>
          </Link>
          <Link href={HELP_PAGE}>
            <p className="block px-4 py-2 text-sm hover:bg-gray-100
             dark:hover:bg-secondary-dark cursor-pointer sm:hidden"
            >
              Help
            </p>
          </Link>
          <Link href={SETTINGS_PAGE}>
            <p className="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-secondary-dark cursor-pointer">
              Settings
            </p>
          </Link>
          <button
            type="button"
            className="px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-secondary-dark min-w-full text-left"
            onClick={() => handleSignOut()}
          >
            Sign out
          </button>
        </div>
      </ReactModal>
      {children(setIsOpen)}
    </Fragment>
  );
};

export default NavAuthModal;
